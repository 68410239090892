<template>
    <div class="main">
        <div class="max_div" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 0.8)">
            <div class="teb_header" style="display: flex; justify-content: space-between; ">
                <div class="input-box">

                    <el-input class="top_input" style="width: 120px;" :size="tabsize" v-model="cname" placeholder="学生姓名"></el-input>
                    <el-input class="top_input" style="width: 120px;" :size="tabsize" v-model="sname" placeholder="学校名称"></el-input>
                    <el-select v-model="gname" clearable placeholder="年级" :size="tabsize" class="top_input" style="width: 90px;">
                        <el-option v-for="item in sel1" :key="item.id" :label="item.gname" :value="item.gname">
                        </el-option>
                    </el-select>
                    <el-select v-model="pname" clearable placeholder="班级" :size="tabsize" class="top_input" style="width: 90px;">
                        <el-option v-for="item in sel4" :key="item.id" :label="item.cname" :value="item.cname">
                        </el-option>
                    </el-select>
                    <el-select v-model="funid" clearable placeholder="视力" :size="tabsize" class="top_input" style="width: 100px;">
                        <el-option v-for="item in options" :key="item.id" :label="item.cname" :value="item.cname">
                        </el-option>
                    </el-select>
                    <el-select v-model="value1" clearable placeholder="请选择" :size="tabsize" style="width: 170px;" class="top_input1">
                        <el-option v-for="item in yydd" :key="item.cname" :label="item.cname" :value="item.cname">
                        </el-option>
                    </el-select>

                    <el-button class="top_input" style="width: 70px;margin-left: 8px;" :size="tabsize" type="" icon="el-icon-search" @click="search()">搜索</el-button>
                    <el-button class="top_input" style="width: 70px;" :size="tabsize" type="" icon="el-icon-refresh" @click="low()">重置</el-button>
                    <el-button class="top_input" style="width: 100px;" :size="tabsize" type="" @click="post()" :disabled="value1 == ''">导出筛查报表</el-button>
                    <el-button class="top_input" style="width: 100px;" :size="tabsize" type="" @click="post1()" :disabled="value1 == ''">导出教委数据</el-button>
                    <!-- <div>
                        <el-button :size="tabsize" type="" icon="el-icon-search" @click="search()">搜索</el-button>
                        <el-button :size="tabsize" type="" icon="el-icon-refresh" @click="low()">重置</el-button>
                    </div> -->

                </div>
                <!-- <div>
                    <el-button :size="tabsize" type="" @click="post()" :disabled="value1 == ''">导出筛查报表</el-button>
                    <el-button :size="tabsize" type="" @click="post1()" :disabled="value1 == ''">导出教委数据</el-button>
                    
                </div> -->
            </div>

            <div class="table">
                <template>
                    <el-table :data="tableData" tooltip-effect="dark" :size="tabsize" border stripe
                        style="width: 100%;overflow: auto;" @selection-change="handleSelectionChange" ref="multipleTable">

                        <el-table-column label="学校" width="" align="center">
                            <template slot-scope="scope">{{ scope.row.sname }}</template>
                        </el-table-column>
                        <el-table-column label="学校类型" width="" align="center">
                            <template slot-scope="scope">{{ scope.row.sclvl | slvl1 }}</template>
                        </el-table-column>
                        <el-table-column label="年级" width="90" align="center">
                            <template slot-scope="scope">{{ scope.row.gname }}</template>
                        </el-table-column>
                        <el-table-column label="班级" width="90" align="center">
                            <template slot-scope="scope">{{ scope.row.pname }}</template>
                        </el-table-column>
                        <el-table-column label="姓名" width="90" align="center">
                            <template slot-scope="scope">{{ scope.row.cname }}</template>
                        </el-table-column>
                        <el-table-column label="年龄" width="90" align="center">
                            <template slot-scope="scope">{{ scope.row.age | age1 }}</template>
                        </el-table-column>
                        <el-table-column label="身高" width="90" align="center">
                            <template slot-scope="scope">{{ scope.row.height / 10 }}</template>
                        </el-table-column>
                        <el-table-column label="体重" width="90" align="center">
                            <template slot-scope="scope">{{ scope.row.weight / 100 }}</template>
                        </el-table-column>
                        <el-table-column label="左眼屈光" width="90" align="center">
                            <template slot-scope="scope">{{ scope.row.ls / 100 }}</template>
                        </el-table-column>
                        <el-table-column label="右眼屈光" width="90" align="center">
                            <template slot-scope="scope">{{ scope.row.rs / 100 }}</template>
                        </el-table-column>
                        <el-table-column label="裸眼左" width="90" align="center">
                            <template slot-scope="scope">{{ scope.row.lev / 100 }}</template>
                        </el-table-column>
                        <el-table-column label="裸眼右" width="90" align="center">
                            <template slot-scope="scope">{{ scope.row.rev / 100 }}</template>
                        </el-table-column>
                        <el-table-column label="散光左" width="90" align="center">
                            <template slot-scope="scope">{{ scope.row.lc / 100 }}</template>
                        </el-table-column>
                        <el-table-column label="散光右" width="90" align="center">
                            <template slot-scope="scope">{{ scope.row.rc / 100 }}</template>
                        </el-table-column>
                        <el-table-column label="ok镜" width="90" align="center">
                            <template slot-scope="scope">{{ scope.row.isok | isok }}</template>
                        </el-table-column>
                        <el-table-column label="状态" width="90" align="center">
                            <template slot-scope="scope">{{ scope.row.lab }}</template>
                        </el-table-column>
                    </el-table>
                </template>
            </div>



            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.length">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { getBaseUrl } from '../../requset/http';
export default {
    data() {
        return {
            labelPosition: 'right',
            tableData: [],
            loading: false,
            sort: "b.id",
            order: "desc",
            currentPage: 1, //默认显示第一页
            pageSize: 20, //默认每页显示10条
            totalNum: "", //总页数

            value1: '',
            options: [{
                id: '1',
                cname: '正常视力'
            }, {
                id: '2',
                cname: '边缘视力'
            }, {
                id: '3',
                cname: '轻度不良'
            }, {
                id: '4',
                cname: '中度不良'
            }, {
                id: '5',
                cname: '重度不良'
            }],
            cname: '',
            sname: '',
            pname: '',
            gname: '',
            funid: '',
            sel1: [],
            sel4: [],
            yydd: []
        };
    },
    methods: {
        handleSizeChange(val) {
            // this.loading = true;
            this.pageSize = val;
            this.currentPage = 1; // 选择分页大小后跳转第一页
            this.list();
            console.log("分页大小" + val);
        },
        // 第几页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.list();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            // this.selectedRows = val;
            if (val.length > 1) {
                this.$refs.multipleTable.clearSelection();
                this.$refs.multipleTable.toggleRowSelection(val.pop());
            }
            if (val.length != 0) {
                this.selectProtocolId = val[val.length - 1].id;
            }
        },
        list() {
            this.loading = true;
            console.log("val", this.value1);
            this.$api.screening.list({
                "jPage.pageNum": this.currentPage,
                "jPage.pageSize": this.pageSize,
                "jPage.sort": this.sort,
                "jPage.order": this.order,
                "jPage.cname": this.cname,
                "jPage.sname": this.sname,
                "jPage.gname": this.gname,
                "jPage.pname": this.pname,
                "jPage.funid": this.funid,
                "jPage.scrtime": this.value1
            }).then((res) => {
                console.log(res);
                this.tableData = res.obj.list
                this.loading = false
                this.totalNum = res.obj.totalPage; // 总页数
                this.tableData.length = res.obj.totalRow; //总条数
                this.currentPage = res.obj.pageNumber; //当前页码数;
            })
        },
        search() {
            this.currentPage = 1
            this.list()
        },
        low() {
            this.currentPage = 1
            this.cname = ''
            this.sname = ''
            this.gname = ''
            this.pname = ''
            this.funid = ''
            this.list()
        },
        sel() {
            this.$api.screening.sel({}).then((res) => {
                console.log(res);
                this.sel1 = res.obj
            })
        },
        sel2() {
            this.$api.screening.sel3({}).then((res) => {
                console.log(res);
                this.sel4 = res.obj
            })
        },
        yy() {
            this.$api.screening.yysel({}).then((res) => {
                console.log("yysel", res);
                this.yydd = res.obj
                this.value1 = res.obj[0].cname
                this.list()
            })
        },
        post() {
            var info = JSON.parse(sessionStorage.getItem("userInfo"))
            window.location.href = getBaseUrl() + "/pc/qd/v1/screen/export" + '?cname=' + this.cname + '&sname=' + this.sname + '&gname=' + this.gname
                + '&pname=' + this.pname + '&funid=' + this.funid + '&scrtime=' + this.value1 + '&info=' + info.username
            console.log(info);
        },
        post1() {
            var info = JSON.parse(sessionStorage.getItem("userInfo"))
            window.location.href = getBaseUrl() + "/pc/qd/v1/screen/jwexport" + '?cname=' + this.cname + '&sname=' + this.sname + '&gname=' + this.gname
                + '&pname=' + this.pname + '&funid=' + this.funid + '&scrtime=' + this.value1 + '&info=' + info.username
            console.log(info);
        }

    },
    computed: {
        tabsize() {
            this.$setTableHeight2("teb_header", "table");
            return this.$store.state.tabsize;
        },
    },
    created() {
        this.sel(),
            this.sel2(),
            this.yy()
    },
    mounted() {
    }
};
</script>
<style  scoped>
.main {
    position: relative;
    width: 100%;
    height: 98%;
}

.teb_header .el-input {
    width: auto;
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.hang {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* .ss {
    margin-right: 1vw;
    width: 16vw !important;
} */


.table {}

.name {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

}

.ww {
    display: flex;
    justify-content: flex-end;
}

.box2 {
    display: flex;
    justify-content: space-between;
}

.text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.el-table::before {
    z-index: inherit;
}

.input-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* 顶部筛选框样式 */
.top_input {
    width: 120px;
    margin: 0 8px 0.5% 0;
}

/deep/ .el-table__header-wrapper .el-checkbox {
    display: none
}
</style>